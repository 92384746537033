<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <select-warehouse-model
          v-model="id_warehouse"
          :label="$t('labels.warehouse')"
          dense
          outlined
          clearable
          hide-details
          @change="onWarehouseUpdate"
        ></select-warehouse-model>
      </v-col>
      <v-col cols="8">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :label="placeholder"
          @keyupEnter="inputScan"
          :auto-close="false"
        ></input-qr-scan-model>
      </v-col>
    </v-row>

    <div class="mt-3">
      <v-alert
        color="error"
        text
        dark
        class="text-center mb-3"
        v-if="item.curernt_locations"
      >
        <span class="fs-14">{{ $t("labels.current_position") }}: </span> <br />
        <span class="text-h6">{{ item.curernt_locations.join("; ") }}</span>
      </v-alert>
      <v-alert
        color="primary"
        text
        dark
        class="text-center mb-0"
        v-if="item.available_locations"
      >
        <span class="fs-14">Vị trí khả dụng tham khảo: </span> <br />
        <span class="text-h6">{{ item.available_locations.join("; ") }}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "WrongReturn",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    id_warehouse: null,
    item: {},
  }),
  computed: {
    placeholder() {
      if (!this.item || !this.item.sku_uid) {
        return this.$t("labels.uid");
      } else {
        return this.$t("labels.position");
      }
    },
  },
  mounted() {},
  methods: {
    onWarehouseUpdate() {
      if (!this.id_warehouse) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput();
      });
    },

    async inputScan() {
      if (!this.code) {
        return false;
      }

      if (this.code.includes("@") || !this.item || !this.item.sku_uid) {
        await this.scanUid();
      } else {
        await this.scanLocation();
      }
    },

    async scanLocation() {
      try {
        await httpClient.post("/goods-receipt/v1/create-history-wrong-return", {
          cell_id: this.code,
          id_warehouse: this.id_warehouse,
          uid: this.item.sku_uid,
        });
        this.code = null;
        const item = {};
        this.item = { ...item };
        document.getElementById("success_sound_player").play();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },

    async scanUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/get-item-wrong-return",
          {
            id_warehouse: this.id_warehouse,
            uid: this.code,
          }
        );
        this.item = { ...data };
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
